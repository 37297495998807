/* eslint react/jsx-one-expression-per-line: 0 */
import React from "react";
import styled, { AnyStyledComponent } from "styled-components";

import { Banner } from "../banner/index";
import { BulletPoint } from "../bullet-point/index";
import { Button } from "../button/index";
import { PageBlock } from "../page-block/index";

import { primaryColor, secondaryColor } from "../../constants";
import SellersBannerImg from "../../assets/banner_images/sellers_banner.jpg";

const SellersPageWrapper: AnyStyledComponent = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ListWrapper: AnyStyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 1rem 0;
`;

const BoldText: AnyStyledComponent = styled.span`
  font-weight: bold;
`;

const Space: AnyStyledComponent = styled.div`
  height: 3rem;
`;

const SellersPage: React.FC = (): JSX.Element => {
  return (
    <SellersPageWrapper>
      <Banner
        title="STL Homesold for Sellers"
        desc=""
        height="45vh"
        img={SellersBannerImg}
      />
      <PageBlock bgColor={primaryColor} regWidth>
        <h1>We help you sell your house no matter who you are.</h1>
        <p>
          <BoldText>We will help you </BoldText>
          regardless of your experience or the condition of your house. That
          also means...
        </p>
        <ListWrapper>
          <BulletPoint
            text="No home repairs needed"
            color={secondaryColor}
            bigSize={false}
          />
          <BulletPoint
            text="No inspections needed"
            color={secondaryColor}
            bigSize={false}
          />
        </ListWrapper>
        <p>
          We will stop by to see the house and that&apos;s it. Just
          <BoldText> sell your house as is. </BoldText>
        </p>
      </PageBlock>
      <PageBlock bgColor={secondaryColor} regWidth>
        <h1>Why sell to us?</h1>
        <p>
          When you normally sell your house, you have to go through a
          <BoldText> complicated process </BoldText>
          of finding a real estate agent, pricing your house, setting up a
          timeline to sell your house, getting inspections for your house,
          schedule showings with potential buyers, and then go through a
          laborious process of neogiation before closing your final sale.
        </p>
        <p>
          When you sell with us, the process is much
          <BoldText> simpler.</BoldText> You talk to us, we&apos;ll come over
          and see your property, and then we&apos;ll provide you with a
          <BoldText> cash offer </BoldText>
          for the closing deal.
        </p>
        <p>
          In the end we are about taking the stress and time commitment out of
          needing to sell your home.
        </p>
      </PageBlock>
      <PageBlock bgColor={primaryColor} regWidth>
        <h1>No additional or hidden fees</h1>
        <p>
          We will not charge you anything for selling your house.
          <BoldText>We do not make comission </BoldText> so you save money. We
          will also...
        </p>
        <ListWrapper>
          <BulletPoint
            text="Give you a cash offer"
            color={secondaryColor}
            bigSize={false}
          />
          <BulletPoint
            text="Close within 30 days"
            color={secondaryColor}
            bigSize={false}
          />
        </ListWrapper>
        <p>
          Our goal is to make this as
          <BoldText> smooth</BoldText> and
          <BoldText> stress-free </BoldText>
          of a process which not only benefits you, but benefits everyone.
        </p>
      </PageBlock>
      <PageBlock bgColor={secondaryColor} regWidth>
        <h1>Who is STL Homesold for?</h1>
        <p>
          It’s for anyone who needs a really convenient and simple home selling
          process. Common examples include...
        </p>
        <ListWrapper>
          <BulletPoint
            text="Looking to downsize"
            color={primaryColor}
            bigSize={false}
          />
          <BulletPoint
            text="Transitioning to senior living"
            color={primaryColor}
            bigSize={false}
          />
          <BulletPoint
            text="Have no time to sell your house"
            color={primaryColor}
            bigSize={false}
          />
          <BulletPoint
            text="Want to sell fast for a cash offer"
            color={primaryColor}
            bigSize={false}
          />
          <BulletPoint
            text="Have inherited properties"
            color={primaryColor}
            bigSize={false}
          />
          <BulletPoint
            text="Need simplicity and convenience"
            color={primaryColor}
            bigSize={false}
          />
        </ListWrapper>{" "}
        <Button text="Sell With Us Today" link="/contact" width="15rem" />
      </PageBlock>
      <Space />
    </SellersPageWrapper>
  );
};

export { SellersPage };
