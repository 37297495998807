import React from "react";
import styled, { AnyStyledComponent } from "styled-components";

import { Banner } from "../banner/index";
import { Button } from "../button/index";
import { PageBlock } from "../page-block/index";

import { primaryColor, secondaryColor } from "../../constants";
import BuyersBannerImage from "../../assets/banner_images/buyers_banner.jpg";

const BuyersPageWrapper: AnyStyledComponent = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const BoldText: AnyStyledComponent = styled.span`
  font-weight: bold;
`;

const Space: AnyStyledComponent = styled.div`
  height: 3rem;
`;

const BuyersPage: React.FC = (): JSX.Element => {
  return (
    <BuyersPageWrapper>
      <Banner
        title="STL Homesold for Buyers"
        desc=""
        height="50vh"
        img={BuyersBannerImage}
      />
      <PageBlock bgColor={primaryColor} regWidth>
        <h1>Need to buy a property? We have your back.</h1>
        <p>
          If you are in the real estate business and need to buy properties, we
          have plenty of options for you. Join our buyer&apos;s list for great
          prices and great deals.
        </p>
        <Button
          text="Join our buyer's list"
          link="/join-buyers-list"
          width="15rem"
        />
      </PageBlock>
      <PageBlock bgColor={secondaryColor} regWidth>
        <h1>Realtors, we know that you&apos;re important.</h1>
        <p>
          You help and inform your buyers on which properties they should buy.
          You provide them with the necessary information needed for the buyer
          to make a well thought out purchase.
        </p>
        <p>
          We’ll be more than happy to
          <BoldText>
            &nbsp;provide you a good offer on any wholesale properties&nbsp;
          </BoldText>
          and if you have any
          <BoldText> referrals for properties </BoldText>
          that need to be sold quickly, we’ll provide you ourbest deal.
        </p>
        <p>
          Just give us a call at (314) 488-7577, email us, or contact us through
          our social media.
        </p>
      </PageBlock>
      <Space />
    </BuyersPageWrapper>
  );
};

export { BuyersPage };
