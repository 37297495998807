/* eslint @typescript-eslint/no-use-before-define: 0 */
import React from "react";
import styled, { AnyStyledComponent } from "styled-components";

import { SignUpForm } from "./email_form";
// import { primaryColor, secondaryColor } from "../../constants";
import buyersListImg from "../../assets/banner_images/buyers_list_banner.jpg";

const BuyersListPageWrapper: AnyStyledComponent = styled.main`
  height: calc(100vh - 15rem);
  max-height: 150vh;
  margin: 2rem 0;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 700px) {
    margin: 7rem 0 2rem 0;
    height: calc(100vh - 7rem);
  }

  @media (max-height: 700px) {
    min-height: 100vh;
  }
`;

const FormBackground: AnyStyledComponent = styled.div`
  height: 100%;
  width: 90%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: linear-gradient(
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    url(${buyersListImg}) fixed center;
  background-size: cover;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.25);
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

  @media (max-width: 700px) {
    width: 100%;
    box-shadow: none;
  }
`;

const ContentWrapper: AnyStyledComponent = styled.div`
  max-width: 60%;

  h1 {
    font-family: "Merriweather";
    font-size: 36px;
    text-align: left;
    margin: 0 0 1rem 0;
  }

  @media (max-width: 700px) {
    max-width: 90%;
    box-shadow: none;
  }
`;

const BuyersListPage: React.FC = (): JSX.Element => {
  return (
    <BuyersListPageWrapper>
      <FormBackground>
        <ContentWrapper>
          <h1>Join the STL Homesold buyers list.</h1>
          <SignUpForm />
        </ContentWrapper>
      </FormBackground>
    </BuyersListPageWrapper>
  );
};

export { BuyersListPage };
