/* eslint @typescript-eslint/no-use-before-define: 0 */
import React, { useState } from "react";
import styled, { AnyStyledComponent } from "styled-components";

import { useForm } from "../../useForm";
import availabilityImg from "../../assets/banner_images/availability_banner.jpg";
import { highlightColor, primaryColor, secondaryColor } from "../../constants";
import zipCodeData from "../../zip_code_data.json";

const AvailabilityPageWrapper: AnyStyledComponent = styled.main`
  height: calc(100vh - 15rem);
  margin: 2rem 0;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 700px) {
    margin: 7rem 0 2rem 0;
    height: calc(100vh - 7rem);
  }
`;

const AvailabilityTool: AnyStyledComponent = styled.div`
  height: 100%;
  width: 90%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: linear-gradient(
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    url(${availabilityImg}) fixed center;
  background-size: cover;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.25);
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

  h1 {
    max-width: 50%;
    font-family: "Merriweather";
    font-size: 36px;
    text-align: center;
    margin: 0;
  }

  p {
    max-width: 50%;
    margin: 1rem;
    font-family: "Noto Sans";
    font-size: 24px;
    text-align: center;
  }

  @media (max-width: 700px) {
    width: 100%;
    box-shadow: none;

    h1 {
      max-width: 90%;
    }

    p {
      max-width: 90%;
    }
  }
`;

const InputForm: AnyStyledComponent = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);

  button {
    height: 2.5rem;
    border: none;
    border-radius: 0 1px 1px 0;
    background-color: ${highlightColor};
    width: 6rem;
    font-family: "Noto Sans";
    font-size: 18px;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }
`;

interface InputProps {
  valid: string;
}

const InputBox: AnyStyledComponent = styled.label`
  input {
    height: 2.5rem;
    width: 15rem;
    padding: 0 0 0 1rem;
    background: ${(props: InputProps): string => {
      if (props.valid === "default") return primaryColor;
      if (props.valid === "valid") return secondaryColor;
      if (props.valid === "invalid") return "#ed654a";
      return primaryColor;
    }};
    border: none;
    border-radius: 1px 0 0 1px;
    font-size: 18px;

    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }

  input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  @media (max-width: 700px) {
    input {
      width: 10rem;
    }
  }
`;

const AvailabilityPage: React.FC = (): JSX.Element => {
  const { values, handleChange, handleSubmit } = useForm(
    { zipCode: "" },
    checkAvailability
  );
  const [valid, setValid] = useState("default");

  let title = "";
  let desc = "";

  if (valid === "default") {
    title = "Are we in your neighborhood?";
    desc = "Just enter your zip code (5-digit) and we'll check you you.";
  } else if (valid === "valid") {
    title = "Yes we are in your area!";
    desc = "Contact us and we'll get started";
  } else if (valid === "invalid") {
    title = "Sorry.";
    desc = "Unfortunately, we are not in your area right now.";
  }

  function checkAvailability(): void {
    // For efficiency reasons, we use a dictionary as a data structure
    // to store the zip code JSON array and then use that dictionary
    // to check for the existance of a zip code in our list.
    // This is still faster than searching through the array.
    const dict: { [id: number]: string } = {};

    zipCodeData.forEach(zipCodeObject => {
      dict[zipCodeObject.zipCode] = zipCodeObject.county;
    });

    const found = dict[parseInt(values.zipCode, 10)];

    if (found) setValid("valid");
    else setValid("invalid");
  }

  return (
    <AvailabilityPageWrapper>
      <AvailabilityTool>
        <h1>{title}</h1>
        <p>{desc}</p>
        <InputForm onSubmit={handleSubmit}>
          <InputBox htmlFor="zipCode" valid={valid}>
            <input
              type="number"
              name="zipCode"
              onChange={handleChange}
              value={values.zipCode}
              required
            />
          </InputBox>
          <button type="submit">Submit</button>
        </InputForm>
      </AvailabilityTool>
    </AvailabilityPageWrapper>
  );
};

export { AvailabilityPage };
