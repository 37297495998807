/* eslint @typescript-eslint/no-use-before-define: 0 */
import React, { useState } from "react";
import styled, { AnyStyledComponent } from "styled-components";

import { FormResult } from "./result";
import { InputField } from "../input-field/index";
import { SubmitButton } from "./submit_button";
import { submitForm } from "./submitForm";
import { lineColor } from "../../constants";
import { useForm } from "../../useForm";

const ContactPageWrapper: AnyStyledComponent = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header: AnyStyledComponent = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-family: "Merriweather";
    font-size: 36px;
    margin: 0;
  }

  p {
    font-family: "Noto Sans";
    font-size: 20px;
    margin: 0;
  }

  @media (max-width: 700px) {
    margin-top: 7rem;

    h1 {
      width: 90%;
    }

    p {
      width: 90%;
    }
  }
`;

const Form: AnyStyledComponent = styled.form`
  width: 40%;
  margin: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  h1 {
    font-family: "Merriweather";
    font-size: 32px;
    margin: 0;
  }

  hr {
    background-color: ${lineColor};
    border: none;
    height: 2px;
    width: 100%;
    margin-bottom: 1rem;
  }

  @media (max-width: 700px) {
    width: 90%;
  }
`;

const FieldGroup: AnyStyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2rem;
  width: 100%;
`;

const LocationInputWrapper: AnyStyledComponent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const BigInputField: AnyStyledComponent = styled.label`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  textarea {
    border: solid #d9d9d9 2px;
    margin-bottom: 1rem;
    height: 8rem;
    max-width: 100%;
    font-family: "Noto Sans";
    font-size: 18px;
  }
`;

const Captcha: AnyStyledComponent = styled.div`
  height: 4.5rem;
  margin-bottom: 3rem;
`;

const ContactPage: React.FC = (): JSX.Element => {
  const [displayResult, setDisplayResult] = useState("");
  const { values, handleChange, handleSubmit } = useForm(
    {
      name: "",
      phone: "",
      email: "",
      address: "",
      addressExtra: "",
      city: "",
      state: "",
      zipCode: "",
      comments: ""
    },
    () => submitForm(values, setDisplayResult)
  );

  return (
    <ContactPageWrapper>
      <Header>
        <h1>Contact Us</h1>
        <p>Talk to us about any questions or feedback that you have.</p>
      </Header>
      <Form id="contact-form" onSubmit={handleSubmit}>
        <FieldGroup>
          <h1>General Information</h1>
          <hr />
          <InputField
            label="First and Last Name"
            name="name"
            type="text"
            regWidth
            regHeight
            onChange={handleChange}
            value={values.name}
            required
          />
          <InputField
            label="Phone Number"
            name="phone"
            type="text"
            regWidth
            regHeight
            onChange={handleChange}
            value={values.phone}
            required
          />
          <InputField
            label="Email Address"
            name="email"
            type="text"
            regWidth
            regHeight
            onChange={handleChange}
            value={values.email}
            required
          />
        </FieldGroup>
        <FieldGroup>
          <h1>Additional Information (Optional)</h1>
          <hr />
          <InputField
            label="Street Address"
            name="address"
            type="text"
            regWidth
            regHeight
            onChange={handleChange}
            value={values.address}
            required={false}
          />
          <InputField
            label="Street Address Line 2 (If needed)"
            name="addressExtra"
            type="text"
            regWidth
            regHeight
            onChange={handleChange}
            value={values.addressExtra}
            required={false}
          />
          <LocationInputWrapper>
            <InputField
              label="City"
              name="city"
              type="text"
              regWidth={false}
              regHeight
              onChange={handleChange}
              value={values.city}
              required={false}
            />
            <InputField
              label="State"
              name="state"
              type="text"
              regWidth={false}
              regHeight
              onChange={handleChange}
              value={values.state}
              required={false}
            />
            <InputField
              label="Zip Code"
              name="zipCode"
              type="text"
              regWidth={false}
              regHeight
              onChange={handleChange}
              value={values.zipCode}
              required={false}
            />
          </LocationInputWrapper>
          <BigInputField>
            Questions or feedback
            <textarea
              name="comments"
              onChange={handleChange}
              value={values.comments}
            />
          </BigInputField>
        </FieldGroup>
        <Captcha
          className="g-recaptcha"
          data-sitekey="6LcjLdIUAAAAADl-ciInVu-9fkynJNhobE5VRc2w"
        />
        <SubmitButton text="Contact Us" />
        <FormResult displayResult={displayResult} />
      </Form>
    </ContactPageWrapper>
  );
};

export { ContactPage };
