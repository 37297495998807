import React from "react";
import styled, { AnyStyledComponent } from "styled-components";

import { textPrimaryColor } from "../../constants";

const BannerWrapper: AnyStyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: linear-gradient(
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.4)
    ),
    url(${(props: BannerProps): string => props.img}) fixed center;
  background-size: cover;
  color: ${textPrimaryColor};

  height: ${(props: BannerProps): string => props.height};
  width: 100vw;

  @media (max-width: 1000px) {
    background-attachment: scroll;
  }

  @media (max-width: 700px) {
    flex-direction: column;
    height: 20rem;
    justify-content: flex-end;
    align-items: flex-start;

    background: linear-gradient(
        rgba(255, 255, 255, 0.6),
        rgba(255, 255, 255, 0.6)
      ),
      url(${(props: BannerProps): string => props.img}) center;
    background-size: cover;
  }
`;

const MainText: AnyStyledComponent = styled.h1`
  font-family: "Merriweather";
  font-size: 36px;
  text-align: center;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  max-width: 594px;

  @media (max-width: 700px) {
    font-size: 28px;
    margin: 0 0 0 1rem;
    text-align: left;
    width: 80%;
  }
`;

const Desc: AnyStyledComponent = styled.p`
  font-size: 24px;
  text-align: center;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  margin-top: 0;
  max-width: 594px;

  @media (max-width: 700px) {
    font-size: 14px;
    margin: 0 0 2rem 1rem;
    text-align: left;
  }
`;

interface BannerProps {
  title: string;
  desc: string;
  height: string;
  img: string;
}

const Banner: React.FC<BannerProps> = (props): JSX.Element => {
  const { title, desc, height, img } = props;

  return (
    <BannerWrapper height={height} img={img}>
      <MainText>{title}</MainText>
      <Desc>{desc}</Desc>
    </BannerWrapper>
  );
};

export { Banner };
