import React from "react";
import styled, { AnyStyledComponent } from "styled-components";

import { BannerWithLogo } from "../banner/banner_logo";
import { BulletPoint } from "../bullet-point/index";
import { Button } from "../button/index";
import { PageBlock } from "../page-block/index";

import { primaryColor, secondaryColor, lineColor } from "../../constants";
import HomeBannerImg from "../../assets/banner_images/home_banner.jpg";
import buyersInfo from "./buyer_points.json";
import sellersInfo from "./seller_points.json";

const HomePageWrapper: AnyStyledComponent = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ComparisonSection: AnyStyledComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1rem 0 2rem 0;

  @media (max-width: 1100px) {
    flex-direction: column;
    margin: -2rem 0;
  }

  @media (max-width: 700px) {
    margin: -3rem 0;
  }
`;

const ComparisonGroup: AnyStyledComponent = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 28px;
  }

  @media (max-width: 1100px) {
    margin: 2rem 0;

    h1 {
      font-size: 18px;
    }
  }
`;

const VerticalDivider: AnyStyledComponent = styled.div`
  border: solid ${lineColor} 1px;
  margin: 0 3rem;
`;

const ButtonGroup: AnyStyledComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const BoldText: AnyStyledComponent = styled.span`
  font-weight: bold;
`;

const ContactBlock: AnyStyledComponent = styled.div`
  margin: 2rem 0;

  p {
    font-size: 22px !important;
  }

  @media (max-width: 700px) {
    margin-top: 3rem;

    p {
      font-size: 18px !important;
    }
  }
`;

const HomePage: React.FC = (): JSX.Element => {
  const buyerPoints = buyersInfo.map(element => {
    return <BulletPoint text={element.text} color={secondaryColor} bigSize />;
  });

  const sellerPoints = sellersInfo.map(element => {
    return <BulletPoint text={element.text} color={secondaryColor} bigSize />;
  });

  return (
    <HomePageWrapper>
      <BannerWithLogo
        title="Looking to buy or sell a house quickly?"
        desc="We can help you with that"
        height="60vh"
        img={HomeBannerImg}
      />
      <PageBlock bgColor={primaryColor} regWidth={false}>
        <h1>If you are...</h1>
        <ComparisonSection>
          <ComparisonGroup>
            <h1>Selling a home</h1>
            {sellerPoints}
          </ComparisonGroup>
          <VerticalDivider />
          <ComparisonGroup>
            <h1>Buying homes</h1>
            {buyerPoints}
          </ComparisonGroup>
        </ComparisonSection>
        <ContactBlock>
          <p>
            Call us at
            <BoldText> (314) 488-7577</BoldText>
          </p>
          <p>
            Or email us at
            <BoldText> STLHomesold@gmail.com</BoldText>
          </p>
        </ContactBlock>
      </PageBlock>
      <PageBlock bgColor={secondaryColor} regWidth>
        <h1>Where are we buying homes?</h1>
        <p>
          We operate in six counties: St. Louis County, St. Charles County,
          Jefferson County, Franklin County, Lincoln County, and Warren County.
        </p>
        <p>
          Use the tool below to see if we are in your area and we’ll get back to
          you shortly!
        </p>
        <Button
          text="Check Availability"
          link="/check-availability"
          width="15rem"
        />
      </PageBlock>
      <PageBlock bgColor={primaryColor} regWidth>
        <h1>Learn more about STL Homesold</h1>
        <p>
          We know there&apos;s a lot more to talk about, so click below to get
          more information.
        </p>
        <ButtonGroup>
          <Button text="Selling your house" link="/sellers" width="15rem" />
          <Button text="Buying a house" link="/buyers" width="15rem" />
        </ButtonGroup>
      </PageBlock>
    </HomePageWrapper>
  );
};

export { HomePage };
