import emailjs from "emailjs-com";

interface FormValues {
  name: string;
  phone: string;
  email: string;
  address?: string;
  addressExtra?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  comments?: string;
}

const submitForm = (values: FormValues, setDisplayResult: Function): void => {
  emailjs
    .send(
      "contact_service",
      "contact_form",
      values,
      `${process.env.REACT_APP_USER_ID}`
    )
    .then(
      result => {
        if (result)
          setDisplayResult("You have successfuly sent us a contact request.");
      },
      error => {
        if (error)
          setDisplayResult(
            `Sorry, your form did not successfuly submit. Make sure that you clicked the ReCaptcha checkbox. Please email STLHomesold@gmail.com for any questions you have.`
          );
      }
    );
};

export { submitForm };
