import React from "react";
import styled, { AnyStyledComponent } from "styled-components";
import MaterialIcon from "material-icons-react";

import { textPrimaryColor } from "../../constants";

interface StyleProps {
  bigSize: boolean;
  color: string;
}

const BulletPointWrapper: AnyStyledComponent = styled.div<StyleProps>`
  border-radius: 1px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  color: ${textPrimaryColor};
  background-color: ${(props: StyleProps): string => props.color};
  height: ${(props: StyleProps): string => (props.bigSize ? "3rem" : "2.5rem")};
  width: ${(props: StyleProps): string => (props.bigSize ? "30rem" : "21rem")};
  margin: 0.75rem 0;

  display: flex;
  flex-direction: row;
  align-items: center;

  h1 {
    font-size: ${(props: StyleProps): string =>
      props.bigSize ? "22px" : "18px"} !important;
    font-family: "Noto Sans" !important;
  }

  @media (max-width: 700px) {
    height: 2rem;
    width: 90vw;

    h1 {
      font-size: 14px !important;
      margin-left: 0;
    }
  }
`;

const IconWrapper: AnyStyledComponent = styled.div<StyleProps>`
  height: ${(props: StyleProps): string =>
    props.bigSize ? "1.3rem" : "1.2rem"};
  width: ${(props: StyleProps): string =>
    props.bigSize ? "1.6rem" : "1.3rem"};

  background: ${textPrimaryColor};
  border-radius: 50%;

  margin: 0 0.75rem;
  padding-top: ${(props: StyleProps): string =>
    props.bigSize ? "0.3rem" : "0.1rem"};

  @media (max-width: 700px) {
    display: none;
  }
`;

const MobileIconWrapper: AnyStyledComponent = styled.div`
  height: 1rem;
  width: 1.2rem;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem;
  padding-top: 0.2rem;

  background: ${textPrimaryColor};
  border-radius: 50%;

  @media (min-width: 701px) {
    display: none;
  }
`;

interface BulletPointProps {
  text: string;
  color: string;
  bigSize: boolean;
}

const BulletPoint: React.FC<BulletPointProps> = (props): JSX.Element => {
  const { text, color, bigSize } = props;

  return (
    <BulletPointWrapper color={color} bigSize={bigSize}>
      <IconWrapper bigSize={bigSize}>
        {bigSize ? (
          <MaterialIcon icon="home" size={20} color={color} />
        ) : (
          <MaterialIcon icon="home" size={18} color={color} />
        )}
      </IconWrapper>
      <MobileIconWrapper>
        <MaterialIcon icon="home" size={15} color={color} />
      </MobileIconWrapper>
      <h1>{text}</h1>
    </BulletPointWrapper>
  );
};

export { BulletPoint };
