import React from "react";
import styled, { AnyStyledComponent } from "styled-components";

import { primaryColor, textPrimaryColor, lineColor } from "../../constants";

const FooterWrapper: AnyStyledComponent = styled.footer`
  background-color: ${primaryColor};
  color: ${textPrimaryColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Divider: AnyStyledComponent = styled.hr`
  background-color: ${lineColor};
  margin-bottom: 1.5rem;
  width: 90%;
  height: 2px;
  border: none;
`;

const Header: AnyStyledComponent = styled.h1`
  font-family: "Merriweather";
  font-size: 36px;
  font-weight: normal;
  margin: 2rem 0 0 0;

  @media (max-width: 700px) {
    font-size: 28px;
    margin-top: 1rem;
  }
`;

const DetailText: AnyStyledComponent = styled.a`
  font-size: 18px;
  margin: 0.5rem;

  @media (max-width: 700px) {
    font-size: 14px;
    margin: 0.25rem;
  }
`;

const StyledLink: AnyStyledComponent = styled.a`
  color: ${textPrimaryColor};
  font-size: 18px;
  text-decoration: none;
  margin: 0.5rem 2rem;

  @media (max-width: 700px) {
    font-size: 14px;
    margin: 0.4rem;
  }
`;

const LinkGroup: AnyStyledComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 90%;
  margin-bottom: 2rem;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const CopyRight: AnyStyledComponent = styled.p`
  font-size: 14px;
  margin: -0.5rem 0 1.5rem 0;
`;

const Footer: React.FC = (): JSX.Element => {
  return (
    <FooterWrapper>
      <Divider />
      <Header>Call Us</Header>
      <DetailText>(314) 488-7577</DetailText>
      <Header>Email Us</Header>
      <DetailText>STLHomesold@gmail.com</DetailText>
      <Header>Quick Links</Header>
      <LinkGroup>
        <StyledLink href="/">Home</StyledLink>
        <StyledLink href="/buyers">Buyers</StyledLink>
        <StyledLink href="/sellers">Sellers</StyledLink>
        <StyledLink href="/contact">Contact Us</StyledLink>
        <StyledLink href="/check-availability">Check Availability</StyledLink>
        <StyledLink href="/join-buyers-list">Join Buyers List</StyledLink>
        <StyledLink href="/about">About Us</StyledLink>
      </LinkGroup>
      <Divider />
      <CopyRight>© STL Homesold 2019</CopyRight>
    </FooterWrapper>
  );
};

export { Footer };
