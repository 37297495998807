import React from "react";
import styled, { AnyStyledComponent } from "styled-components";

import { highlightColor, textPrimaryColor } from "../../constants";

const ButtonLink: AnyStyledComponent = styled.a`
  background-color: ${highlightColor};
  border-radius: 1px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  height: 3rem;
  width: ${(props: ButtonProps): string => props.width};
  margin: 2rem 2rem;

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${textPrimaryColor};
  font-size: 20px;
  text-align: left;
  text-decoration: none;

  @media (max-width: 700px) {
    height: auto;
    width: auto;
    margin: 1rem 0;
    padding: 0.5rem 1rem;

    background-color: ${highlightColor};
    border-radius: 1px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    font-size: 14px;
  }
`;

interface ButtonProps {
  text: string;
  link: string;
  width: string;
}

const Button: React.FC<ButtonProps> = (props): JSX.Element => {
  const { text, link, width } = props;

  return (
    <ButtonLink href={link} width={width}>
      {text}
    </ButtonLink>
  );
};

export { Button };
