import React from "react";
import styled, { AnyStyledComponent } from "styled-components";

import { secondaryColor, textPrimaryColor } from "../../constants";

const InfoStripWrapper: AnyStyledComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  background-color: ${secondaryColor};
  width: 100%;

  h1 {
    color: ${textPrimaryColor};
    font-size: 14px;
    margin: 0.5rem 2rem;
  }

  @media (max-width: 1100px) {
    display: none;
  }
`;

const InfoStrip: React.FC = (): JSX.Element => {
  return (
    <InfoStripWrapper>
      <h1>STLHomesold@gmail.com</h1>
      <h1>Phone: (314) 488-7577</h1>
      <h1>See our Facebook Page</h1>
      <h1>See our WeChat</h1>
    </InfoStripWrapper>
  );
};

export { InfoStrip };
