import React from "react";
import styled, { AnyStyledComponent } from "styled-components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { AboutPage } from "../about-us-page/index";
import { AvailabilityPage } from "../availability-page/index";
import { BuyersListPage } from "../buyers-list-page/index";
import { BuyersPage } from "../buyers-page/index";
import { ContactPage } from "../contact-page/index";
import { HomePage } from "../home-page/index";
import { Footer } from "../footer/index";
import { Navbar } from "../navbar/index";
import { SellersPage } from "../sellers-page/index";

import { primaryColor } from "../../constants";

const AppWrapper: AnyStyledComponent = styled.div`
  background-color: ${primaryColor};
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;

  h1 {
    font-weight: normal;
  }
`;

const App: React.FC = () => {
  return (
    <Router>
      <AppWrapper>
        <Navbar />
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route path="/buyers">
            <BuyersPage />
          </Route>
          <Route path="/sellers">
            <SellersPage />
          </Route>
          <Route path="/contact">
            <ContactPage />
          </Route>
          <Route path="/check-availability">
            <AvailabilityPage />
          </Route>
          <Route path="/join-buyers-list">
            <BuyersListPage />
          </Route>
          <Route path="/about">
            <AboutPage />
          </Route>
        </Switch>
      </AppWrapper>
      <Footer />
    </Router>
  );
};

export default App;
