import React from "react";
import styled, { AnyStyledComponent } from "styled-components";

interface StyleProps {
  regWidth: boolean;
  regHeight: boolean;
}

const InputFieldWrapper: AnyStyledComponent = styled.label`
  width: 100%;
  width: ${(props: StyleProps): string => (props.regWidth ? "100%" : "8rem")};
  display: flex;
  flex-direction: column;
  font-size: 18px;

  input {
    height: ${(props: StyleProps): string =>
      props.regHeight ? "2rem" : "8rem"};
    margin-bottom: 1rem;
    padding-left: 1rem;
    border: solid #d9d9d9 2px;
    font-size: 18px;
  }

  @media (max-width: 700px) {
    width: 100%;
  }
`;

interface InputFieldProps {
  label: string;
  name: string;
  type: string;
  regWidth: boolean;
  regHeight: boolean;
  onChange: any;
  value: string;
  required: boolean;
}

const InputField: React.FC<InputFieldProps> = (props): JSX.Element => {
  const {
    label,
    name,
    type,
    regWidth,
    regHeight,
    onChange,
    value,
    required
  } = props;

  return (
    <InputFieldWrapper htmlFor={name} regWidth={regWidth} regHeight={regHeight}>
      {label}
      {required ? (
        <input
          type={type}
          name={name}
          onChange={onChange}
          value={value}
          required
        />
      ) : (
        <input type={type} name={name} onChange={onChange} value={value} />
      )}
    </InputFieldWrapper>
  );
};

export { InputField };
