import React from "react";
import styled, { AnyStyledComponent } from "styled-components";
import MaterialIcon from "material-icons-react";

import { ReactComponent as TextLogoMobile } from "../../assets/misc/text_logo_mobile.svg";
import { primaryColor, textPrimaryColor, lineColor } from "../../constants";

const MobileScreenWrapper: AnyStyledComponent = styled.nav`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  right: 0;
  background: ${primaryColor};

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentWrapper: AnyStyledComponent = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header: AnyStyledComponent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-family: "Merriweather";
    font-size: 28px;
  }
`;

const TitleWrapper: AnyStyledComponent = styled.a`
  color: #000;
  text-decoration: none;
  display: flex;
  justify-content: center;
`;

const MobileButton: AnyStyledComponent = styled.div`
  display: none;

  @media (max-width: 1100px) {
    display: flex;
    height: 1.9rem;
    margin: 1.3rem 0;
  }
`;

const Divider: AnyStyledComponent = styled.hr`
  background-color: ${lineColor};
  border: none;
  height: 2px;
  width: 100%;
  margin-top: 0rem;
  margin-bottom: 1.5rem;
`;

const LinkGroup: AnyStyledComponent = styled.nav`
  height: calc(100vh - 20rem);
  margin: 5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const StyledLink: AnyStyledComponent = styled.a`
  color: ${textPrimaryColor};
  font-family: "Merriweather";
  font-size: 28px;
  text-decoration: none;
`;

interface MobileScreenProps {
  open: boolean;
  closeFunction: Function;
}

const MobileScreen: React.FC<MobileScreenProps> = (props): JSX.Element => {
  const { open, closeFunction } = props;

  return (
    <div>
      {open ? (
        <MobileScreenWrapper>
          <ContentWrapper>
            <Header>
              <TitleWrapper href="/">
                <TextLogoMobile />
              </TitleWrapper>
              <MobileButton onClick={closeFunction}>
                <MaterialIcon icon="clear" color={textPrimaryColor} size={40} />
              </MobileButton>
            </Header>
            <Divider />
            <LinkGroup>
              <StyledLink href="/buyers" onClick={closeFunction}>
                Buyers
              </StyledLink>
              <StyledLink href="/sellers" onClick={closeFunction}>
                Sell Your House
              </StyledLink>
              <StyledLink href="/contact" onClick={closeFunction}>
                Contact Us
              </StyledLink>
              <StyledLink href="/check-availability" onClick={closeFunction}>
                Check Availability
              </StyledLink>
              <StyledLink href="/join-buyers-list" onClick={closeFunction}>
                Join Buyers List
              </StyledLink>
              <StyledLink href="/about" onClick={closeFunction}>
                About Us
              </StyledLink>
            </LinkGroup>
          </ContentWrapper>
        </MobileScreenWrapper>
      ) : null}
    </div>
  );
};

export { MobileScreen };
