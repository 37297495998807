import React from "react";
import styled, { AnyStyledComponent } from "styled-components";

interface WrapperProps {
  bgColor: string;
}

const PageBlockWrapper: AnyStyledComponent = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: ${(props: WrapperProps): string => props.bgColor};
  text-align: center;

  h1 {
    font-family: "Merriweather";
    font-size: 36px;
  }

  p {
    font-size: 18px;
    margin: 0.5rem 0;
  }

  @media (max-width: 700px) {
    h1 {
      font-size: 28px;
      text-align: left;
      margin: 1rem 0;
    }

    p {
      font-size: 14px;
      text-align: left;
      margin: 0.4rem 0;
    }
  }
`;

interface ContentProps {
  regWidth: boolean;
}

const Content: AnyStyledComponent = styled.div<ContentProps>`
  max-width: ${(props: ContentProps): string =>
    props.regWidth ? "50%" : "80%"};
  margin: 3rem 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 700px) {
    margin: 3rem 0;
    max-width: 90vw;
    align-items: flex-start;
  }
`;

interface PageBlockProps {
  bgColor: string;
  regWidth: boolean;
}

const PageBlock: React.FC<PageBlockProps> = (props): JSX.Element => {
  const { bgColor, regWidth, children } = props;

  return (
    <PageBlockWrapper bgColor={bgColor} regWidth={regWidth}>
      <Content regWidth={regWidth}>{children}</Content>
    </PageBlockWrapper>
  );
};

export { PageBlock };
