import React from "react";
import styled, { AnyStyledComponent } from "styled-components";

import { BannerWithLogo } from "../banner/banner_logo";
import { Button } from "../button/index";
import { PageBlock } from "../page-block/index";

import { primaryColor, secondaryColor } from "../../constants";
import AboutBannerImg from "../../assets/banner_images/about_banner.jpg";

const AboutPageWrapper: AnyStyledComponent = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ButtonGroup: AnyStyledComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Space: AnyStyledComponent = styled.div`
  height: 3rem;
`;

const AboutPage: React.FC = (): JSX.Element => {
  return (
    <AboutPageWrapper>
      <BannerWithLogo
        title="About Us"
        desc=""
        height="45vh"
        img={AboutBannerImg}
      />
      <PageBlock bgColor={primaryColor} regWidth>
        <h1>Serving the local community</h1>
        <p>
          We are locally based in St. Louis and serve the communities around the
          greater St. Louis area.
        </p>
        <p>
          STL Homesold is new but the team has been in the real estate world for
          years so while we have the experience to help you, we also have a lot
          more flexibility than most other teams and big corporations.
        </p>
        <p>
          We are also a small team so you&apos;ll always be able to contact the
          people that matter the most rather than going from person to person
          for specific questions. You&apos;ll never have to say something you
          already said for multiple people.
        </p>
      </PageBlock>
      <PageBlock bgColor={secondaryColor} regWidth>
        <h1>Coming soon! Connect with STL Homesold</h1>
        <p>
          We have a Facebook page and also are on WeChat so click below to
          connect with us.
        </p>
        <ButtonGroup>
          <Button
            text="Connect on Facebook (Coming soon)"
            link="/"
            width="25rem"
          />
          <Button text="Join our WeChat (Coming soon)" link="/" width="20rem" />
        </ButtonGroup>
      </PageBlock>
      <Space />
    </AboutPageWrapper>
  );
};

export { AboutPage };
