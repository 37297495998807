/* eslint no-shadow: 0 */
import { useState } from "react";

const useForm = (structure: any, callback: Function): any => {
  const [values, setValues] = useState(structure);

  const handleSubmit = (event: any): void => {
    if (event) event.preventDefault();
    callback();
    setValues(structure);
  };

  const handleChange = (event: any): void => {
    event.persist();

    setValues((values: any): void => ({
      ...values,
      [event.target.name]: event.target.value
    }));
  };

  return {
    handleChange,
    handleSubmit,
    values
  };
};

export { useForm };
