import React from "react";
import styled, { AnyStyledComponent } from "styled-components";

import { highlightColor } from "../../constants";

const ButtonStyle: AnyStyledComponent = styled.button`
  background-color: ${highlightColor};
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  border: none;
  border-radius: 1px;
  height: 3rem;
  width: 10rem;
  margin: -1rem 0 1rem 0;

  font-family: "Noto Sans";
  font-size: 20px;
`;

interface ButtonProps {
  text: string;
}

const SubmitButton: React.FC<ButtonProps> = (props): JSX.Element => {
  const { text } = props;

  return <ButtonStyle type="submit">{text}</ButtonStyle>;
};

export { SubmitButton };
