/* eslint jsx-a11y/label-has-associated-control: 0 */
import React, { useRef, useEffect } from "react";
import styled, { AnyStyledComponent } from "styled-components";

import { highlightColor } from "../../constants";

const FormWrapper: AnyStyledComponent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Form: AnyStyledComponent = styled.form`
  width: 100%;
`;

const FieldGroup: AnyStyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  margin: 0.5rem 0;
  font-family: "Noto Sans";
  font-size: 18px;

  input {
    height: 2rem;
    width: 20rem;
    margin-top: 0.2rem;
    padding-left: 0.5rem;
    border: none;
    border-radius: 1px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    font-family: "Noto Sans";
    font-size: 18px;

    @media (max-width: 700px) {
      width: 15rem;
    }
  }
`;

const Submit: AnyStyledComponent = styled.input`
  height: 3rem;
  width: 8rem;
  margin-top: 1rem;

  background-color: ${highlightColor};
  border: none;
  border-radius: 1px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  font-family: "Noto Sans";
  font-size: 18px;
  -webkit-appearance: none;
  -moz-appearance: none;
`;

// Note: Do not worry about types here since this is just a stylized version
// of an embedded form rather than a custom form that we made.
const SignUpForm: React.FC = (): JSX.Element => {
  const email: any = useRef();
  const firstName: any = useRef();
  const lastName: any = useRef();
  const phone: any = useRef();

  const handleChange = (e: any): any => console.log(e.target.value);

  useEffect(() => {
    const emailVar = email.current;
    const firstNameVar = firstName.current;
    const lastNameVar = lastName.current;
    const phoneVar = phone.current;

    emailVar.addEventListener("input", handleChange);
    firstNameVar.addEventListener("input", handleChange);
    lastNameVar.addEventListener("input", handleChange);
    phoneVar.addEventListener("input", handleChange);

    return (): any => {
      emailVar.removeEventListner("input", handleChange);
      firstNameVar.removeEventListner("input", handleChange);
      lastNameVar.removeEventListner("input", handleChange);
      phoneVar.removeEventListner("input", handleChange);
    };
  });

  return (
    <FormWrapper id="mc_embed_signup">
      <Form
        action="https://gmail.us4.list-manage.com/subscribe/post?u=9c311423d40e4cb9d9cbca951&amp;id=3e89dd4d49"
        method="post"
        name="mc-embedded-subscribe-form"
        class="validate"
        target="_blank"
        novalidate
      >
        <div>
          <FieldGroup className="mc-field-group">
            <label htmlFor="mce-EMAIL">Email Address *</label>
            <input
              type="email"
              ref={email}
              name="EMAIL"
              className="required email"
              id="mce-EMAIL"
            />
          </FieldGroup>
          <FieldGroup>
            <label htmlFor="mce-FNAME">First Name *</label>
            <input
              type="text"
              ref={firstName}
              name="FNAME"
              className="required"
              id="mce-FNAME"
            />
          </FieldGroup>
          <FieldGroup>
            <label htmlFor="mce-LNAME">Last Name *</label>
            <input
              type="text"
              ref={lastName}
              name="LNAME"
              className="required"
              id="mce-LNAME"
            />
          </FieldGroup>
          <FieldGroup>
            <label htmlFor="mce-MMERGE6">Phone *</label>
            <input
              type="text"
              ref={phone}
              name="MMERGE6"
              className="required"
              id="mce-MMERGE6"
            />
          </FieldGroup>
          <div
            style={{ position: "absolute", left: "-5000px" }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_9c311423d40e4cb9d9cbca951_3e89dd4d49"
              tabIndex={-1}
              value=""
            />
          </div>
          <Submit
            type="submit"
            value="Subscribe"
            name="subscribe"
            className="button"
          />
        </div>
      </Form>
    </FormWrapper>
  );
};

export { SignUpForm };
