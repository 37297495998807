import React from "react";
import styled, { AnyStyledComponent } from "styled-components";

import { secondaryColor } from "../../constants";

interface FormStyleProps {
  displayResult: string;
}

const FormResultWrapper: AnyStyledComponent = styled.div`
  display: ${(props: FormStyleProps): string =>
    props.displayResult ? "flex" : "none"};
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  background-color: ${(props: FormStyleProps): string =>
    props.displayResult === "You have successfuly sent us a contact request."
      ? secondaryColor
      : "#ed654a"};
`;

const FormResultText: AnyStyledComponent = styled.h1`
  font-family: "Noto Sans" !important;
  font-size: 18px !important;
  margin: 1rem !important;
`;

interface FormResultProps {
  displayResult: string;
}

const FormResult: React.FC<FormResultProps> = (props): JSX.Element => {
  const { displayResult } = props;

  return (
    <FormResultWrapper displayResult={displayResult}>
      <FormResultText>{displayResult}</FormResultText>
    </FormResultWrapper>
  );
};

export { FormResult };
