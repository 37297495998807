const primaryColor = "#FBFBFB";
const secondaryColor = "#E1F6B3";
const highlightColor = "#81C8E7";
const textPrimaryColor = "#2B2B2B";
const textSecondaryColor = "#636363";
const lineColor = "#A6A6A6";

export {
  primaryColor,
  secondaryColor,
  highlightColor,
  textPrimaryColor,
  textSecondaryColor,
  lineColor
};
