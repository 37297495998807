import React, { useState } from "react";
import styled, { AnyStyledComponent } from "styled-components";
import MaterialIcon from "material-icons-react";

import { InfoStrip } from "../info-strip/index";
import { MobileScreen } from "./mobile_screen";
import { ReactComponent as TextLogo } from "../../assets/misc/text_logo.svg";
import { ReactComponent as TextLogoMobile } from "../../assets/misc/text_logo_mobile.svg";
import { textPrimaryColor, lineColor } from "../../constants";

const HeaderWrapper: AnyStyledComponent = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 700px) {
    z-index: 2;
    background: none;
    position: absolute;
  }
`;

const NavbarWrapper: AnyStyledComponent = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  height: 3rem;
  width: 90%;
  margin: 1rem 0 0 0;
`;

const TitleWrapper: AnyStyledComponent = styled.a`
  color: #000;
  font-family: "Merriweather";
  font-size: 36px;
  text-decoration: none;

  display: flex;
  justify-content: center;
`;

const DesktopLogo: AnyStyledComponent = styled.div`
  display: flex;
  @media (max-width: 1100px) {
    display: none;
  }
`;

const MobileLogo: AnyStyledComponent = styled.div`
  display: none;
  @media (max-width: 1100px) {
    display: flex;
  }
`;

const Logo: AnyStyledComponent = styled(TextLogo)`
  border: none;
`;

const LinkGroup: AnyStyledComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1100px) {
    display: none;
  }
`;

const Link: AnyStyledComponent = styled.a`
  color: ${textPrimaryColor};
  font-size: 18px;
  text-decoration: none;
  text-align: center;
  margin-left: 2rem;
`;

const Divider: AnyStyledComponent = styled.hr`
  background-color: ${lineColor};
  border: none;
  height: 2px;
  width: 90%;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
`;

const MobileButton: AnyStyledComponent = styled.div`
  display: none;

  @media (max-width: 1100px) {
    display: flex;
    height: 1.9rem;
  }
`;

const Navbar: React.FC = (): JSX.Element => {
  const [open, setOpen] = useState(false);

  return (
    <HeaderWrapper>
      <InfoStrip />
      <NavbarWrapper>
        <TitleWrapper href="/">
          <DesktopLogo>
            <Logo />
          </DesktopLogo>
          <MobileLogo>
            <TextLogoMobile />
          </MobileLogo>
        </TitleWrapper>
        <LinkGroup>
          <Link href="/buyers">Buyers</Link>
          <Link href="/sellers">Sell Your House</Link>
          <Link href="/contact">Contact Us</Link>
          <Link href="/check-availability">Check Availability</Link>
          <Link href="/join-buyers-list">Join Buyers List</Link>
          <Link href="/about">About Us</Link>
        </LinkGroup>
        <MobileButton onClick={(): void => setOpen(!open)}>
          <MaterialIcon icon="menu" color={textPrimaryColor} size={40} />
        </MobileButton>
      </NavbarWrapper>
      <Divider />
      <MobileScreen open={open} closeFunction={(): void => setOpen(false)} />
    </HeaderWrapper>
  );
};

export { Navbar };
